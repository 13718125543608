import type { Product, ProductAttributes } from '@wemade-vsf/catalog/lib/runtime/product/types';
import { getPrice } from '@wemade-vsf/catalog/lib/runtime/product/getters/productGetters';

function isQuoteProductCallback(product: Product, attributes: ProductAttributes, isQuotable: boolean) {
  const priceProduct = product?.configurable_product_options_selection?.variant ?? product;
  const { regular } = getPrice(priceProduct);

  if (!regular) return true;
  const isUnquoted = attributes['wm_no_order_product']?.raw_value
    ? attributes['wm_no_order_product'].raw_value === '1'
    : false;

  if (regular > 50_000 && !isUnquoted) {
    return true;
  }
  return isQuotable;
}

export default (context, inject) => {
  let wm = context.$wm ?? {}
  if (wm.catalog) {
    let catalog = {
      ...wm.catalog,
      isQuoteProductCallback
    }
    wm.catalog = catalog;
    inject('wm', wm);
  }
}