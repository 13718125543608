





























import {
  defineComponent,
  PropType,
  computed,
  ref,
  onMounted
} from '@nuxtjs/composition-api';
import StoreFilter from './StoreFilter.vue';
import type { Category, Tag } from '../types';

export default defineComponent({
  name: 'PlacesSearch',
  props: {
    categories: {
      type: Array as PropType<Category[]>,
      default: () => []
    },
    tags: {
      type: Array as PropType<Tag[]>,
      default: () => []
    }
  },
  components: {
    StoreFilter
  },
  emits: ['select', 'filterChanged'],
  setup (props, { emit }) {
    const selectedCategories = ref<number[]>([]);
    const filterCategories = computed(() => props.categories.map(category => {
      return {
        value: category.category_id,
        label: category.name,
        selected: selectedCategories.value.includes(category.category_id)
      }
    }));

    const selectedTags = ref<number[]>([]);
    const filterTags = computed(() => props.tags.map(tag => {
      return {
        value: tag.tag_id,
        label: tag.name,
        selected: selectedTags.value.includes(tag.tag_id)
      }
    }));

    const autocomplete = ref<HTMLInputElement | null>(null);

    const currentPlace = ref(null);
    function clearCurrentPlace () {
      currentPlace.value = null;
      if (autocomplete.value) {
        autocomplete.value.value = '';
      }
      emit('select', null)
    }

    function setCurrentPlace (item) {
      currentPlace.value = item;
      emit('select', item);
    }

    function updateCategories (categoryId) {
      const index = selectedCategories.value.indexOf(categoryId);
      index === -1
        ? selectedCategories.value.push(categoryId)
        : selectedCategories.value.splice(index, 1);

      emitFilter();
    }

    function updateTags (tagId) {
      const index = selectedTags.value.indexOf(tagId);
      index === -1
        ? selectedTags.value.push(tagId)
        : selectedTags.value.splice(index, 1);

      emitFilter();
    }

    function emitFilter() {
      emit('filterChanged', {
        categories: selectedCategories.value,
        tags: selectedTags.value
      })
    }

    onMounted(() => {
      if (!autocomplete.value) return
      const ac = new google.maps.places.Autocomplete(
        autocomplete.value,
        {
          fields: ['geometry.location', 'name']
        }
      );
      ac.addListener('place_changed', () => {
        setCurrentPlace(ac.getPlace());
      })
    })

    return {
      filterCategories,
      filterTags,
      autocomplete,
      currentPlace,
      clearCurrentPlace,
      setCurrentPlace,
      updateCategories,
      updateTags
    }
  }
})
