import { ref, useContext, reactive, computed } from '@nuxtjs/composition-api';
import { Logger } from '@wemade-vsf/core';
import type { Store, LocatorStore } from '../../types';
import type { StateInterface } from './useStoreLocator';

const state = reactive<StateInterface>({
  categories: [],
  tags: []
})

export function useStoreLocator(apiKey: string) {
  const { app } = useContext();
  const loading = ref<boolean>(false);
  const mapsLoaded = ref<boolean>(false);
  const stores = ref<LocatorStore[]>([]);

  function loadMaps (): Promise<void> {
    return new Promise((resolve, reject) => {
      if (mapsLoaded.value) resolve();
      window['GoogleMapsInit'] = resolve;
      let scriptTag = document.createElement('script');
      scriptTag.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,geometry&callback=GoogleMapsInit`)
      document.body.appendChild(scriptTag)
    })
  }

  async function load(): Promise<void> {
    loading.value = true;
    try {
      const { data } = await app.$vsf.$magento.api.storeLocatorData();
      state.categories = data?.WmStoreLocatorCategories?.items ?? [];
      state.tags = data?.WmStoreLocatorTags?.items ?? [];
      stores.value = data?.WmStoreLocatorStores?.items
        ? data.WmStoreLocatorStores.items.map((store: Store): LocatorStore => {
          return {
            ...store,
            lat: parseFloat(store.lat),
            lng: parseFloat(store.lng),
            zoom: parseFloat(store.zoom),
            distance: null,
            isVisible: true,
            marker: null,
            tags: state.tags.filter(tag => store.tag_ids.indexOf(tag.tag_id) > -1),
            categories: state.categories.filter(cat => store.category_ids.indexOf(cat.category_id) > -1)
          }
        })
        : [];
      
      await loadMaps();
    } catch (err) {
      Logger.error(err);
    } finally {
      loading.value = false;
    }
  }

  return {
    load,
    loading,
    categories: computed(() => state.categories),
    tags: computed(() => state.tags),
    stores
  }
}