




























































import {
  defineComponent,
  computed
} from '@nuxtjs/composition-api'
import { SfOverlay } from '@storefront-ui/vue'
import HeaderLogo from 'components/theme/Header/Logo.vue';
import { useUiState, useConfig } from '@wemade-vsf/composables';
import { useSearch } from '@wemade-vsf/search';
import MegaMenu from 'components/megamenu/MegaMenu.vue'
import CartNavigation from 'components/theme/Header/CartNavigation.vue';
import MobileNavigationBar from 'components/theme/Header/MobileNavigationBar.vue';
import TopNavigation from 'components/theme/Header/TopNavigation.vue';

export default defineComponent({
  name: 'AppHeader',
  components: {
    CartNavigation,
    HeaderLogo,
    MegaMenu,
    MobileNavigationBar,
    SfOverlay,
    TopNavigation,
    SearchBar: () => import('components/search/SearchBar.vue'),
    SearchResults: () => import(/* webpackPrefetch: true */ 'components/search/SearchResults.vue'),
    MegaMenuSidebar: () => import('components/theme/Header/MegaMenuSidebar.vue'),
    TopBar: () => import('components/theme/Header/TopBar.vue')
  },
  setup () {
    const { 
      isMobileMenuOpen: isMobileMenu,
      toggleMobileMenu,
      hideSearch
    } = useUiState();

    const {
      isSearchOpen
    } = useSearch();

    const { config } = useConfig();
    const isSticky = config.value.storefront?.sticky_header ?? false;

    const topBarConfig = computed(() => {
      if (config.value?.storefront?.top_bar_block_enabled && config.value?.storefront?.top_bar_block_identifier) {
        return {
          background: config.value.storefront.top_bar_block_background_color,
          identifier: config.value.storefront.top_bar_block_identifier
        }
      }
      return null
    });
    return {
      isMobileMenu,
      isSearchOpen,
      isSticky,
      topBarConfig,
      toggleMobileMenu,
      hideSearch
    }
  }
})
