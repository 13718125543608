






























import {
  defineComponent,
  PropType,
  ref
} from '@nuxtjs/composition-api';

interface FilterItem {
  label: string;
  value: number;
  isSelected: boolean
}

export default defineComponent({
  name: 'StoreFilter',
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array as PropType<FilterItem[]>,
      default: () => []
    }
  },
  emits: ['change'],
  setup (props, { emit }) {
    const isOpen = ref<boolean>(false);

    function toggleContent() {
      isOpen.value = !isOpen.value
    }

    function itemChanged(item: FilterItem) {
      emit('change', item.value)
    }

    return {
      isOpen,
      toggleContent,
      itemChanged
    }
  }
})
