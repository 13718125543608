



















import { defineComponent, ref, useContext, onBeforeMount } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
const cookieName = 'cookieconsent';

export default defineComponent({
  name: 'CookieBanner',
  components: {
    SfButton
  },
  setup () {
    const { app } = useContext();
    const isOpen = ref(false);

    function accept () {
      app.$cookies.set(cookieName, 'true');
      isOpen.value = false;
    }

    onBeforeMount(() => {
      const consent = app.$cookies.get(cookieName);
      if (!consent) {
        isOpen.value = true;
      }
    });

    return {
      isOpen,
      accept
    }
  }
})
