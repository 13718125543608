






























































import {
  defineComponent,
  ref,
  onMounted,
  computed
} from '@nuxtjs/composition-api';
import { useStoreLocator } from '../composables/useStoreLocator';
import SkeletonLoader from 'components/theme/SkeletonLoader.vue';
import PlacesSearch from './PlacesSearch.vue';
import type { LocatorStore } from '../types';

export default defineComponent({
  name: 'StoreLocator',
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    SkeletonLoader,
    PlacesSearch
  },
  setup (props) {
    const { load, stores, categories, tags, loading } = useStoreLocator(props.apiKey);
    const mapRef = ref<HTMLElement | null>(null);

    const map = ref<google.maps.Map | null>(null);
    const currentPlace = ref<google.maps.places.PlaceResult | null>(null);
    function setPlace(place: google.maps.places.PlaceResult) {
      currentPlace.value = place;
      if (place.geometry && place.geometry.location) {
        const latLng = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
        map.value.setCenter(latLng);
        const circle = new google.maps.Circle({
          center: latLng,
          radius: 50000
        });
        map.value.fitBounds(circle.getBounds());
        sortStoresByDistance();
      }
    }

    function sortStoresByDistance() {
      const center = map.value.getCenter();
      stores.value = stores.value.map(store => {
        const position = new google.maps.LatLng(store.lat, store.lng);
        store.distance = (google.maps.geometry.spherical.computeDistanceBetween(center, position) / 1000);
        return store;
      });
      stores.value.sort((store1, store2) => {
        return store1.distance - store2.distance;
      })
    }

    function filterStores({ categories: selectedCategories, tags: selectedTags }: { categories: number[], tags: number[] }) {
      stores.value = stores.value.map(store => {
        const containsCategory = selectedCategories.length === 0
          ? true
          : store.categories.some((cat) => {
            return selectedCategories.indexOf(cat.category_id) > -1
          });
        const containsTag = selectedTags.length === 0
          ? true
          : store.tags.some((tag) => {
            return selectedTags.indexOf(tag.tag_id) > -1;
          });
        const shouldShow = containsTag && containsCategory;
        if (!shouldShow) {
          store.isVisible = false;
          store.marker?.setVisible(false);
        } else {
          store.isVisible = true;
          store.marker?.setVisible(true);
        }
        return store;
      })
    }

    const mapInitialized = ref(false);
    function initMap() {
      if (!mapRef.value) return;
      map.value = new google.maps.Map(mapRef.value, {
        zoom: 12,
        center: { lat: 0.0, lng: 0.0 },
        mapTypeControl: false
      });

      createInitialMapMarkers();
      mapInitialized.value = true;
    }

    function createInitialMapMarkers() {
      if (!map.value) return;
      const bounds = new google.maps.LatLngBounds();

      for (let i = 0, j = stores.value.length; i < j; i++) {
        const store = stores.value[i];
        const position = new google.maps.LatLng(store.lat, store.lng);
        const marker = new google.maps.Marker({
          map: map.value,
          position: position
        });
        marker.setVisible(true);
        bounds.extend(position);
        store.marker = marker;

        marker.addListener('click', () => {
          showStoreInfo(store);
        });
        map.value.fitBounds(bounds);
      }
    }

    const selectedStore = ref<number | null>(null);
    function centerMapOnStore(store: LocatorStore) {
      selectedStore.value = store.store_id;
      const position = new google.maps.LatLng(store.lat, store.lng);
      const zoom = store.zoom || 8;
      map.value?.setCenter(position);
      map.value?.setZoom(zoom);
      showStoreInfo(store);
    }

    const infoWindow = ref<google.maps.InfoWindow | null>(null);
    function showStoreInfo(store: LocatorStore) {
      if (infoWindow.value) infoWindow.value.close();
      var content = '<div class="stores__popup">' +
        '<h4 class="name">' + store.name + '</h4>' +
        '<address class="stores__item">';

      store.address && (content += '<span class="street">' + store.address + '</span>');
      store.postcode && (content += '<span class="city">' + store.postcode + ' ' + store.city + '</span>');
      store.phone && (content += '<a class="phone" href="tel:' + store.phone + '">' + store.phone + '</a>');
      store.email && (content += '<a class="email" href="mailto:' + store.email + '">' + store.email + '</a>');
      store.website && (content += '<a class="website" href="' + store.website + '" target="_blank">' + store.website + '</a>');

      content += '</address>' + '</div>';

      infoWindow.value = new google.maps.InfoWindow({ content });
      infoWindow.value.open(map.value, store.marker);
    }

    const filteredStores = computed(() => stores.value.filter(store => store.isVisible))

    onMounted(async () => {
      await load();
      initMap()
    })

    return {
      loading,
      filteredStores,
      categories,
      tags,
      mapRef,
      mapInitialized,
      selectedStore,
      setPlace,
      filterStores,
      centerMapOnStore
    }
  }
})
